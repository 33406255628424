@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

.gradient {
  background: linear-gradient(90deg, #EDECFC 0%, #DDDBF9 100%);
}
.gradientText {
  background: linear-gradient(90deg, #B0B1B3 0%, #4F46E5 100%);
}
.active {
  font-weight: bold;
  text-decoration: underline;
  
}